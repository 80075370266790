// import type { ComponentType } from "react"
import { useState, useEffect, ComponentType } from "react"

export function captureFormLoadEvent(Component): ComponentType {
    return (props) => {
        useEffect(() => {
            console.log("logging event")
            window?.posthog?.capture("Loaded Survey Lead Form")
        }, [])
        // const handleClick = (e) => {
        //     const text = e.target.textContent
        //     window?.posthog?.capture("Survey Button Clicked", {
        //         text,
        //     })
        // }
        return <Component {...props} />
    }
}

export function captureSurveyAnswerEvent(Component): ComponentType {
    return (props) => {
        const handleClick = (e) => {
            const text = e.target.textContent
            window?.posthog?.capture("Survey Button Clicked", {
                text,
            })
        }
        return <Component {...props} onClick={handleClick} />
    }
}

export function withCloseMenu(Component): ComponentType {
    return (props) => {
        const [closeMenu, setCloseMenu] = useState(false)

        const handleClick = (e) => {
            const target = e.target
            if (!target.classList.contains("close-menu-icon")) {
                console.log("clicked a link!")
                setCloseMenu(true)
            }
        }

        useEffect(() => {
            console.log("in use effect. Closing menu=", closeMenu)
            if (closeMenu) {
                const el = document.getElementsByClassName("close-menu-icon")
                if (el.length) {
                    setInterval(() => {
                        el[0]?.click()
                        console.log("clicked close icon")
                        setCloseMenu(false)
                    }, 1000)
                }
            }
        }, [closeMenu, setCloseMenu])

        return <Component {...props} onClick={handleClick} />
    }
}
